// @mui
import React, { useContext } from "react";
import { Box, Card, Link, Stack, Typography, alpha, useTheme } from "@mui/material";
// data
// components
import ChatMessageInput from "./message/ChatMessageInput";
import ChatHeaderDetail from "./header/ChatHeaderDetail";
import ChatMessageList from "./message/ChatMessageList";
import { useWidgetSettingsContext } from "./settings";
import { ChatContext } from "./context";
import { useBoolean } from "./hooks/use-boolean";

// ----------------------------------------------------------------------

export default function Chat() {
  const settings = useWidgetSettingsContext();
  const chat = useContext(ChatContext);
  const theme = useTheme();

  const loading = useBoolean(false);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Card
        id="widget-height"
        sx={{
          [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
          },
          display: "flex",
          boxShadow: (theme) => theme.shadows[5],
          backgroundColor: "transparent",

          height: {
            xs: "100dvh",
            sm: `calc(100vh - 186px - ${settings.bottomSpacing}px)`,
            md: `calc(75vh - ${settings.bottomSpacing}px)`,
          },
        }}
      >
        <Stack
          flexGrow={1}
          sx={{
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            borderRadius: {
              xs: 0,
              sm: 2,
            },
          }}
        >
          <ChatHeaderDetail />
          <Stack
            direction="row"
            flexGrow={1}
            sx={{
              overflow: "hidden",
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              backgroundColor: "background.paper",
            }}
          >
            <Stack flexGrow={1} sx={{ minWidth: 0, position: "relative" }}>
              <ChatMessageList />
              <ChatMessageInput />
            </Stack>
          </Stack>
        </Stack>
      </Card>
      {(settings.plan ? settings.poweredBy : true) && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            bottom: 0,
            boxShadow: (theme) => theme.shadows[4],
            ...(settings.position === "left"
              ? {
                  right: 0,
                }
              : {
                  left: 0,
                }),
            transform: "translateY(calc(100% + 8px))",
            px: 2,
            py: 1,
            backgroundColor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Stack direction="row" gap={0.75} alignItems="center">
            <Typography variant="subtitle2" color="text.secondary" fontSize={14}>
              Made
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              version="1.1"
              viewBox="0 0 90 90"
              style={{
                height: 24,
              }}
            >
              <g fill={theme.palette.primary.main}>
                <polygon
                  xmlns="http://www.w3.org/2000/svg"
                  points="71,48.7 71,18.2 54.8,9 38.7,9 63,22.8 63,36.7 63,53.3 "
                />
                <polygon
                  xmlns="http://www.w3.org/2000/svg"
                  points="29,66 54.8,81 54.7,81 55.1,81.3 71.7,71.7 79.7,57.8 55.3,72 29,56.8 "
                />
                <polygon
                  xmlns="http://www.w3.org/2000/svg"
                  points="61.2,57 61.3,57 46.9,65.2 54.8,69.8 81,54.5 81,35.2 73,21.3 73,49.8 "
                />
                <polygon
                  xmlns="http://www.w3.org/2000/svg"
                  points="61,23.9 34.8,8.7 18.2,18.3 10.2,32.3 35,18 47,25 46.8,25 61,33.2 "
                />
                <polygon
                  xmlns="http://www.w3.org/2000/svg"
                  points="35.2,20.3 9,35.6 9,54.8 17,68.7 17,40.2 28.8,33.2 28.8,33.2 43.2,24.9 "
                />
                <polygon
                  xmlns="http://www.w3.org/2000/svg"
                  points="19,41.3 19,71.8 35.2,81 51.4,81 27,67.2 27,53.3 27,36.7 "
                />
              </g>
            </svg>

            <Typography fontSize={14} variant="subtitle2" color="text.secondary">
              with
            </Typography>

            <Typography fontSize={14} variant="subtitle2" color="text.secondary">
              <Link href="http://oai-widget.com/auth/firebase/register/" target="_blank">
                oai-widget.com
              </Link>
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

"use client";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { BotStatus, ChatDoc, Message } from "./types";
import { useFirestore } from "reactfire";
import { useWidgetSettingsContext } from "./settings";
import { createChatDoc, getMessage } from "./lib/firestore";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  getSessionStorage,
  setSessionStorage,
} from "./hooks/use-session-storage";
import { mockResponse } from "./mockData";
import useOnceEffect from "./hooks/use-once-effect";
import useWidgetChat from "./hooks/use-widget-chat";
import { useBoolean } from "./hooks/use-boolean";
import { ReturnType } from "./hooks/use-boolean";

interface ChatContextType {
  messages: Message[];
  errorMessage: string;
  sendMessage: (userMessage: string) => void;
  clearConversation: ({ persistMode }: { persistMode?: boolean }) => void;
  status: BotStatus;
  setStatus: React.Dispatch<React.SetStateAction<BotStatus>>;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  isHome?: boolean;
  chatId?: string | null | undefined;
  docMessages: Message[];
  isCreatingChat: ReturnType;
}

export const ChatContext = React.createContext<ChatContextType>({
  clearConversation: () => {},
  messages: [],
  sendMessage: () => {},
  status: "online",
  setStatus: () => {},
  chatOpen: false,
  setChatOpen: () => {},
  errorMessage: "",
  chatId: undefined,
  docMessages: [],
  isCreatingChat: {
    onFalse: () => {},
    onTrue: () => {},
    value: false,
    onToggle: () => {},
    setValue: () => {},
  },
});

interface ChatContextProviderProps {
  children: React.ReactNode;
  editMode?: boolean;
  widgetId?: string;
  defaultOpen?: boolean;
  isHome?: boolean;
}

// ----------------------------------------------------------------------

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
  editMode,
  widgetId,
  defaultOpen,
  isHome,
}) => {
  const [messages, setMessages] = useState<ChatDoc["messages"]>([]);
  const [status, setStatus] = useState<BotStatus>("online");
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [chatOpen, setChatOpen] = useState<boolean>(
    (!!editMode || !!defaultOpen) && isSmUp
  );
  const DB = useFirestore();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const isCreatingChat = useBoolean(false);

  const widgetSettings = useWidgetSettingsContext();

  const shouldPersist = widgetSettings.chatPersistence === "tabClosed";

  const isPaid = !!widgetSettings?.plan;

  const [chatId, setChatId] = useState<string | undefined | null>(
    getSessionStorage(`user-widget-id-${widgetId}`)
  );

  const { data, dataStatus } = useWidgetChat({
    widgetId,
    chatId,
  });

  useEffect(() => {
    if (editMode && !widgetSettings.assistant?.assistantId) {
      return;
    }

    if (data && dataStatus === "success") {
      setChatId(data.chatId);
      setMessages(
        [...data.messages].sort(
          (mes1, mes2) => mes1.createdAt.toDate() - mes2.createdAt.toDate()
        )
      );
    }
  }, [data]);

  const sendMessage = useCallback(
    async (userMessage: string) => {
      try {
        setErrorMessage("");
        setStatus("online");

        if (editMode && !widgetSettings.assistant) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: "text",
              role: "user",
              content: userMessage,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus("typing");

          setMessages((prevMessages) => [
            ...prevMessages,
            {
              type: "text",
              role: "assistant",
              content: mockResponse,
              createdAt: Timestamp.now(),
            },
          ]);
          setStatus("online");
          return;
        } else {
          const currentChatId = getSessionStorage(`user-widget-id-${widgetId}`);
          if (
            !currentChatId &&
            widgetSettings.widgetId &&
            widgetSettings.ownerUid
          ) {
            createChatDoc(widgetSettings.widgetId, widgetSettings.ownerUid)
              .then((chatId) => {
                setChatId(chatId);
                setSessionStorage(`user-widget-id-${widgetId}`, chatId);

                setStatus("typing");
                const docRef = doc(DB, `widgets/${widgetId}/chats`, chatId);

                // if (editMode) {
                //   return getMessage(
                //     widgetSettings.widgetId!,
                //     chatId,
                //     widgetSettings.assistant?.assistantId || "",
                //     userMessage,
                //     [],
                //     isPaid
                //   );
                // }

                return setDoc(
                  docRef,
                  {
                    messages: [
                      {
                        type: "text",
                        role: "assistant",
                        content: widgetSettings.startMessage || "Hello there!",
                        createdAt: Timestamp.now(),
                      },
                    ],
                  },
                  { merge: true }
                ).then(() =>
                  getMessage(
                    widgetSettings.widgetId!,
                    chatId,
                    widgetSettings.assistant?.assistantId || "",
                    userMessage,
                    [
                      {
                        type: "text",
                        role: "assistant",
                        content: widgetSettings.startMessage || "Hello there!",
                        createdAt: Timestamp.now(),
                      },
                    ],
                    isPaid
                  )
                );
              })
              .then(() => {
                setTimeout(() => {
                  setStatus("online");
                }, 300);
              })
              .catch((err: any) => {
                setErrorMessage(err?.message);
                setStatus("error");
              });
          } else {
            if (chatId && widgetSettings.widgetId) {
              setStatus("typing");
              if (!messages.length) {
                const docRef = doc(DB, `widgets/${widgetId}/chats`, chatId);
                setDoc(
                  docRef,
                  {
                    messages: [
                      {
                        type: "text",
                        role: "assistant",
                        content: widgetSettings.startMessage || "Hello there!",
                        createdAt: Timestamp.now(),
                      },
                    ],
                  },
                  { merge: true }
                )
                  .then(() =>
                    getMessage(
                      widgetSettings.widgetId!,
                      chatId,
                      widgetSettings.assistant?.assistantId || "",
                      userMessage,
                      [
                        {
                          type: "text",
                          role: "assistant",
                          content:
                            widgetSettings.startMessage || "Hello there!",
                          createdAt: Timestamp.now(),
                        },
                      ],
                      isPaid
                    )
                  )
                  .then(() => {
                    setTimeout(() => {
                      setStatus("online");
                    }, 300);
                  });
                return;
              }
              await getMessage(
                widgetSettings.widgetId,
                chatId,
                widgetSettings.assistant?.assistantId || "",
                userMessage,
                data?.messages.length ? data?.messages : [],
                isPaid
              );
              setTimeout(() => {
                setStatus("online");
              }, 300);
            } else {
              const newChatId = await createChatDoc(
                widgetSettings.widgetId!,
                widgetSettings.ownerUid!
              );
              setChatId(newChatId);
              setSessionStorage(`user-widget-id-${widgetId}`, newChatId);
              setStatus("typing");
              await getMessage(
                widgetSettings.widgetId!,
                newChatId,
                widgetSettings.assistant?.assistantId || "",
                userMessage,
                data?.messages.length ? data?.messages : [],
                isPaid
              );
              setStatus("online");
            }
          }
        }
      } catch (error: any) {
        setErrorMessage(error?.message);
        setStatus("error");
        console.error("Error sending message:", error);
      }
    },
    [messages, status]
  );

  const clearConversation = useCallback(
    async ({ persistMode }: { persistMode?: boolean }) => {
      if (persistMode && data?.messages && !data?.messages?.length) return;
      const id = widgetId || widgetSettings.widgetId;
      const newChatId = await createChatDoc(id!, widgetSettings.ownerUid!);
      setMessages([]);
      setChatId(newChatId);
      setSessionStorage(`user-widget-id-${widgetId}`, newChatId);
    },
    [widgetSettings, widgetId, data?.messages]
  );

  useOnceEffect(
    useCallback(() => {
      if (shouldPersist) {
        isCreatingChat.onTrue();
        clearConversation({ persistMode: true }).then(() => {
          setTimeout(() => {
            isCreatingChat.onFalse();
          }, 500);
        });
      }
    }, [shouldPersist])
  );

  const docMessagesData = isCreatingChat.value ? [] : data?.messages || [];

  const context = useMemo(
    () => ({
      errorMessage,
      clearConversation,
      messages,
      sendMessage,
      status,
      setStatus,
      chatOpen,
      setChatOpen,
      editMode,
      isHome,
      chatId,
      docMessages: docMessagesData,
      isCreatingChat,
    }),
    [
      chatOpen,
      clearConversation,
      messages,
      sendMessage,
      status,
      editMode,
      isHome,
      errorMessage,
      chatId,
      data,
      isCreatingChat,
    ]
  );

  return (
    <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
  );
};

"use client";
import React from "react";
import isEqual from "lodash/isEqual";
import { useMemo, useState, useEffect, useCallback } from "react";

import { localStorageGetItem } from "../../utils/storage-available";

import { SettingsValueProps } from "../types";
import { WidgetSettings, WidgetSettingsContext } from "./settings-context";
import { presetOptions } from "../../theme/options/presets";

// ----------------------------------------------------------------------

type SettingsProviderProps = {
  children: React.ReactNode;
  defaultSettings: SettingsValueProps;
  defaultUISettings?: WidgetSettings;
};

export function WidgetSettingsProvider({
  children,
  defaultSettings,
  defaultUISettings,
}: SettingsProviderProps) {
  const [state, setState] = useState(defaultSettings);

  const uiSettings: WidgetSettings = useMemo(
    () =>
      defaultUISettings
        ? defaultUISettings
        : {
            name: "Virtual Assistant",
            description: "I can help you with your questions.",
            apiKey: null,
            assistant: "",
            bottomSpacing: 24,
            chatPersistence: "never",
            mainColor: "#00A76F",
            mode: "light",
            position: "right",
            poweredBy: true,
            sideSpacing: 24,
            laucherBgColor: "#00A76F",
            startMessage: "Welcome! How can I assist you today?",
            plan: null,
            inputPlaceholder: "What would you like to know?",
            errorSelectingAssistant: false,

            assistantAvatar: {
              preview: "",
            } as any,
            launcherIcon: {
              preview: "",
            } as any,
          },
    []
  );

  useEffect(() => {
    if (defaultUISettings) {
      setWidgetSettings(defaultUISettings);
    }
  }, [defaultUISettings]);
  useEffect(() => {
    if (defaultUISettings?.mode) {
      update("themeMode", defaultUISettings?.mode);
    }
  }, [defaultUISettings?.mode]);

  const [widgetSettings, setWidgetSettings] = useState<WidgetSettings>(uiSettings);

  const resetDefaultUISettings = useCallback(() => {
    setWidgetSettings(uiSettings);
    update("themeMode", "light");
  }, []);

  const setSettings = useCallback(
    <K extends keyof WidgetSettings>(field: K, value: WidgetSettings[K]) => {
      setWidgetSettings((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    []
  );

  useEffect(() => {
    if (widgetSettings.mainColor) {
      const selectedColor = presetOptions.find(
        (preset) => preset.value === widgetSettings.mainColor
      );
      if (selectedColor) {
        update("themeColorPresets", selectedColor.name);
      } else {
        update("themeColorPresets", "custom");
      }
    }
  }, [widgetSettings.mainColor]);

  const updateState = useCallback((updateValue: any) => {
    setState((prevValue: any) => {
      return {
        ...prevValue,
        ...updateValue,
      };
    });
  }, []);

  const update = useCallback(
    (name: string, updateValue: any) => {
      updateState({
        [name]: updateValue,
      });
    },
    [updateState]
  );

  const reset = useCallback(() => {
    setState(defaultSettings);
  }, [defaultSettings]);

  const [openDrawer, setOpenDrawer] = useState(false);

  const isArabic = localStorageGetItem("i18nextLng") === "ar";

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang("ar");
    }
  }, [isArabic]);

  // Direction by lang
  const onChangeDirectionByLang = useCallback(
    (lang: string) => {
      update("themeDirection", lang === "ar" ? "rtl" : "ltr");
    },
    [update]
  );

  // Drawer
  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const canReset = !isEqual(state, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      onUpdate: update,
      // Direction
      onChangeDirectionByLang,
      // Reset
      canReset,
      onReset: reset,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
      ...widgetSettings,
      setSettings,
      resetDefaultUISettings,
    }),
    [
      reset,
      update,
      state,
      canReset,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      onChangeDirectionByLang,
      widgetSettings,
      setSettings,
      resetDefaultUISettings,
    ]
  );

  return (
    <WidgetSettingsContext.Provider value={memoizedValue}>
      {children}
    </WidgetSettingsContext.Provider>
  );
}

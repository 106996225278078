import { DocumentData, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { widgetDB } from "../lib/firebase";

interface Props {
  widgetId?: string | null;
  chatId?: string | null;
}

const useWidgetChat = ({ widgetId, chatId }: Props) => {
  const [data, setData] = useState<DocumentData | undefined>(undefined);
  const [dataStatus, setDataStatus] = useState<"loading" | "error" | "success">(
    "loading"
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!widgetId || !chatId) {
        setData(undefined);
        setDataStatus("loading");
        return;
      }

      const chatRef = doc(widgetDB, "widgets", widgetId, "chats", chatId);

      try {
        const unsubscribe = onSnapshot(chatRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            setData(docSnapshot.data());
            setDataStatus("success");
          } else {
            setData(undefined);
            setDataStatus("error");
          }
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching document:", error);

        setData(undefined);
        setDataStatus("error");
      }
    };

    fetchData();
  }, [widgetId, chatId]);

  return { data, dataStatus };
};

export default useWidgetChat;

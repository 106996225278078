import {
  Timestamp,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getResponse, getResponsePRO, widgetDB } from "./firebase";
import { WidgetDoc } from "./types";
import { ChatDoc } from "../types";
import { setSessionStorage } from "../hooks/use-session-storage";

//-------------------------------------------------------------------

export const DEFAULT_LOGO =
  "https://firebasestorage.googleapis.com/v0/b/openai-widget-dev.appspot.com/o/widgets%2Fdefault-icons%2FSocial_Profile_Mirko%20(1).png?alt=media&token=fd1c0ee7-c9c7-475b-8d87-a7cdd14eb41b";

//-------------------------------------------------------------------

export const getWidgetById = async (widgetId: string): Promise<WidgetDoc> => {
  const docRef = doc(widgetDB, `widgets/${widgetId}`);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as WidgetDoc;
};

export const createChatDoc = async (
  widgetId: string,
  ownerUid: string
): Promise<string> => {
  console.log({ widgetId });
  const newDocId = doc(collection(widgetDB, `widgets/${widgetId}/chats`)).id;

  const newChat: ChatDoc = {
    chatId: newDocId,
    createdAt: Timestamp.now(),
    messages: [],
    createdBy: "user",
    widgetId,
    ownerUid,
    integrationOwnerUid: ownerUid,
  };

  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, newDocId);

  await setDoc(docRef, newChat);

  return newDocId;
};

export const getMessage = async (
  widgetId: string,
  chatId: string,
  assistantId: string,
  message: string,
  messages: any[],
  isPaid?: boolean
) => {
  try {
    const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);
    
    await setDoc(
      docRef,
      {
        messages: [
          ...messages,
          {
            type: "text",
            role: "user",
            content: message,
            createdAt: Timestamp.now(),
          },
        ],
      },
      { merge: true }
    );

    if (isPaid) {
      await getResponsePRO({
        assistantId,
        chatId,
        widgetId,
      });
    } else {
      await getResponse({
        assistantId,
        chatId,
        widgetId,
      });
    }
  } catch (error: any) {
    if (error?.code === "functions/deadline-exceeded") {
      if (isPaid) {
        throw new Error("Sorry, your request timed out.");
      } else {
        throw new Error("Please upgrade to PRO to increase timeout.");
      }
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("timeout")) {
      throw new Error("Timeout. Please try again.");
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("internal")) {
      throw new Error("Something went wrong. Please try again.");
    }
    throw error;
  }
};

export const clearAllConversation = async (
  widgetId: string,
  chatId: string
) => {
  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

  setSessionStorage(`user-widget-id-${widgetId}`, "");
};
